<template>
  <div>
    <div class="product-list">
      <div
        class="product-item"
      >
        <a :href="homelink.link" target="_blank">
          <img :src="getImagePath(homelink.image)" alt="商品图片" class="product-image" />
        </a>
      </div>
    </div>


    <!-- 分类按钮 -->
    <div class="category-buttons">
      <button
        v-for="category in categories"
        :key="category.value"
        @click="goToLink(category.link)"
        class="category-button"
      >
      <img :src="getImagePath(category.icon)"  class="icon"/>
      <span class="text">{{ category.name }}</span>
      </button>
    </div>
    
    <!-- 商品列表 -->
    <div class="product-list">
      <div
        v-for="(product, index) in visibleProducts"
        :key="index"
         :class="['product-subitem', { 'expired': product.status === '已过期' }]"
      >
        <a
          v-if="product.status !== '已过期'"
          :href="product.link"
          target="_blank"
          @click="goToLink(product.link)"
        >
          <img :src="getImagePath(product.image)" alt="商品图片" class="product-subimage" />
        </a>
        <div v-else @click="handleClick(product)" class="product-subimage expired">
          <img :src="getImagePath(product.image)" alt="商品图片" class="product-subimage" />
        </div>
        <div class="product-info">
          
          <h3 class="product-title"><img :src="getImagePath('8.png')" class="icon2"/> &nbsp;{{ product.name }}</h3>
          <h3 :class="['product-end', { 'expired-text': product.status === '已过期' }]"><img :src="getImagePath('7.png')" class="icon2"/> &nbsp;{{ product.status }}</h3>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductList',
  data() {
    return {
      categories: [
      { name: "出国务工", link: "https://example.com/link1", icon: "1.png" },
        { name: "留学", link: "https://example.com/link2", icon: "2.png" },
        { name: "海外旅游", link: "https://example.com/link3", icon: "3.png" },
        { name: "代办签证", link: "https://example.com/link4", icon: "4.png" }
      ],
      homelink:{image: "6.png", link: "https://example.com/product1" },
      products: [
        { name: "德国",  image: "5.png", link: "https://example.com/product1" ,status:'进行中'},
        { name: "英国",  image: "5.png", link: "https://example.com/product2" ,status:'已过期'},
        { name: "日本",  image: "5.png", link: "https://example.com/product3" ,status:'进行中'},
        { name: "美国",  image: "5.png", link: "https://example.com/product4" ,status:'进行中'},
        { name: "商品 1",  image: "5.png", link: "https://example.com/product1" ,status:'已过期'},
        { name: "商品 2",  image: "5.png", link: "https://example.com/product2" ,status:'已过期'},
        { name: "商品 3",  image: "5.png", link: "https://example.com/product3" ,status:'已过期'},
        { name: "商品 4",  image: "5.png", link: "https://example.com/product4" ,status:'进行中'},
        { name: "商品 1",  image: "5.png", link: "https://example.com/product1" ,status:'已过期'},
        { name: "商品 2",  image: "5.png", link: "https://example.com/product2" ,status:'进行中'},
        { name: "商品 3",  image: "5.png", link: "https://example.com/product3" ,status:'进行中'},
        { name: "商品 4",  image: "5.png", link: "https://example.com/product4" ,status:'进行中'},
        { name: "商品 1",  image: "5.png", link: "https://example.com/product1" ,status:'进行中'},
        { name: "商品 2",  image: "5.png", link: "https://example.com/product2" ,status:'已过期'},
        { name: "商品 3",  image: "5.png", link: "https://example.com/product3" ,status:'已过期'},
        { name: "商品 4",  image: "5.png", link: "https://example.com/product4" ,status:'已过期'},
        { name: "商品 1",  image: "5.png", link: "https://example.com/product1" ,status:'进行中'},
        { name: "商品 2",  image: "5.png", link: "https://example.com/product2" ,status:'进行中'},
        { name: "商品 3",  image: "5.png", link: "https://example.com/product3" ,status:'进行中'},
        { name: "商品 4",  image: "5.png", link: "https://example.com/product4" ,status:'进行中'},
        // 更多商品项
      ],
      visibleProducts: [], // 当前可见的商品
      itemsPerPage: 4, // 每次加载的商品数量
      currentPage: 1 // 当前加载的页数
    };
  },
  mounted() {
    this.loadMoreProducts(); // 初次加载第一页数据
    window.addEventListener('scroll', this.handleScroll); // 监听滚动事件
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // 移除滚动事件监听
  },
  methods: {
    getImagePath(imageName) {
      return require(`@/assets/${imageName}`);
    },
    goToLink(link) {
      window.open(link, '_blank'); // 在新标签页中打开链接
    },
    loadMoreProducts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      const nextProducts = this.products.slice(start, end);
      this.visibleProducts = this.visibleProducts.concat(nextProducts);
      this.currentPage++;
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      const clientHeight = document.documentElement.clientHeight;
      const scrollHeight = document.documentElement.scrollHeight;

      // 如果接近页面底部，加载更多商品
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        this.loadMoreProducts();
      }
    },
    handleClick(product) {
      if (product.status === '已过期') {
        alert('活动已过期');
      }
    }
  }
}
</script>

<style>
/* 分类按钮样式 */
.category-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 5%; /* 使用百分比调整间距 */
  max-width:656px; /* 使用视口宽度单位 */
  margin: 0 auto;
}
.category-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 5px;
  transition: background-color 0.3s;
}
.category-button:hover {
  background-color: #e0e0e0;
}
.icon{
  max-height: 40px;
  max-width: 40px;
}
.text {
  margin-top: 5px;
  font-size: 3vw; /* 使用视口宽度单位 */
  text-align: center;
  color: #333;
}
/* 商品列表样式 */
.product-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  margin: 0 auto;
}
.product-item {
  background-color: white;
  border-radius: 8px;
  padding: 10px 2vw; /* 使用视口宽度单位 */
  width: 100%;
  max-width: 90vw; /* 使用视口宽度单位 */
  text-align: center;
  transition: transform 0.2s;
}
.product-item:hover {
  transform: scale(1.05);
}
.product-image {
  width: 100%;
  height: auto; /* 自动调整高度 */
  max-height: 50vh; /* 最大高度为视口的一半 */
  max-width: 90vw; /* 使用视口宽度单位 */
  border-radius: 8px;
  cursor: pointer;
}
.product-subitem {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 90vw; /* 使用视口宽度单位 */
  text-align: center;
  transition: transform 0.2s;
}
.product-subitem.expired .product-subimage {
  opacity: 0.5; /* 图片变白且透明度降低 */
  cursor: not-allowed; /* 设置鼠标指针为不允许状态 */
}
.product-subitem:hover {
  transform: scale(1.05);
}
.product-subimage {
  width: 100%;
  height: auto;
  max-height: 30vh; /* 最大高度为视口的30% */
  max-width: 90vw;
  border-radius: 8px;
  cursor: pointer;
}
.product-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-title {
  text-align: left;
  font-size: 4vw; /* 使用视口宽度单位 */
  font-weight: bold;
  color: #333;
}
.product-end {
  text-align: right;
  font-size: 3vw; /* 使用视口宽度单位 */
  color: #4caf50;
}
.product-end.expired-text {
  color: #9e9e9e; /* 灰色，表示过期 */
}
.icon2 {
  height: 3vw; /* 使用视口宽度单位 */
  width: 3vw;
}

/* 响应式媒体查询 */
@media (min-width: 768px) {
  .product-item, .product-subitem {
    max-width: 600px;
  }
  .icon {
    max-height: 50px;
    max-width: 50px;
  }
  .text, .product-title, .product-end {
    font-size: 16px;
  }
  .icon2 {
    height: 15px;
    width: 15px;
  }
}
</style>
